import AnalyticsContext from '@root/core/src/contexts/analytics/analytics-context';
import AnalyticsService from '@root/core/src/services/analytics-service';
import { useCallback, useContext, useEffect } from '@root/vendor/react';

export default function useAnalytics(analyticsContext, shouldTrackViewEvent = true) {
  const { eventPrefix } = useContext(AnalyticsContext);

  useEffect(() => {
    if (shouldTrackViewEvent) {
      AnalyticsService.trackViewEvent(analyticsContext, {
        eventPrefix,
      });
    }
  }, [analyticsContext, eventPrefix, shouldTrackViewEvent]);

  return {
    trackClick: useCallback((event, properties) => {
      AnalyticsService.trackClickEvent(analyticsContext, event, {
        eventPrefix,
        ...properties,
      });
    }, [analyticsContext, eventPrefix]),
    trackEvent: useCallback((event, properties) => {
      AnalyticsService.trackEvent(analyticsContext, event, {
        eventPrefix,
        ...properties,
      });
    }, [analyticsContext, eventPrefix]),
  };
}
