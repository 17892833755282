import AgeWhenLicensed from '@root/core/src/models/age-when-licensed';
import DobDate from '@root/core/src/models/dob-date';
import dayjs from '@root/vendor/dayjs';
import uuid from '@root/vendor/uuid/v4';
import { obfuscate } from '@root/core/src/utils/strings';

export const MAX_YOUNG_DRIVER_AGE = 19;

export default class Driver {
  constructor() {
    this.accidentPreventionCourse = null;
    this.activeDutyMilitary = null;
    this.ageWhenLicensed;
    this.collegeGraduate = null;
    this.dob;
    this.driversLicenseId;
    this.firstName;
    this.goodStudent = null;
    this.lastName;
    this.licenseNumber;
    this.licenseState;
    this.maritalStatus;
    this.nationalGuardMember = null;
    this.occasionalDriver = null;
    this.pni = false;
    this.refresherPreventionCourse = null;
    this.selected;
    this.universalDriverId = uuid();
  }

  set(key, value) {
    return Object.assign(
      new Driver(),
      this,
      {
        [key]: value,
      },
    );
  }

  key() {
    return `${this.firstName} ${this.lastName} ${this.licenseNumber} ${this.universalDriverId}`;
  }

  fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  age() {
    const now = dayjs();
    const dobDate = dayjs(this.dob.toString(), 'YYYY-MM-DD');
    return now.diff(dobDate, 'years');
  }

  obfuscatedLicenseNumber() {
    return obfuscate(this.licenseNumber, {
      numVisible: 3,
    });
  }

  valid() {
    return !!this.licenseNumber && !!this.licenseState;
  }

  static buildFromData(driver) {
    return Object.assign(
      new Driver(),
      {
        ...driver,
        dob: new DobDate(driver.dob),
        ageWhenLicensed: new AgeWhenLicensed(driver.ageWhenLicensed),
      },
    );
  }

  determineLicenseValidationParams() {
    if (this.driversLicenseId && !this.isLicenseEdited) {
      return {
        driversLicenseId: this.driversLicenseId,
      };
    }

    return {
      licenseNumber: this.licenseNumber,
      licenseState: this.licenseState,
    };
  }

  buildLicenseParams() {
    if (this.driversLicenseId && !this.isLicenseEdited) {
      return {
        driversLicenseId: this.driversLicenseId,
      };
    }

    return {
      licenseNumber: this.licenseNumber,
      licenseState: this.licenseState,
    };
  }

  mergeDriver(newDriver) {
    if (!this.matches(newDriver)) {
      throw new Error('Attempted to merge incompatible drivers');
    }

    return this.setAttributes({
      ...newDriver,
      universalDriverId: this.universalDriverId,
    });
  }

  setAttributes(attributes = {}) {
    return Object.assign(
      new Driver(),
      this,
      attributes,
    );
  }

  matches(driver) {
    const licenseObfucated1 = obfuscate(this.licenseNumber?.toUpperCase(), {
      numVisible: 3,
    });
    const licenseObfucated2 = obfuscate(driver.licenseNumber?.toUpperCase(), {
      numVisible: 3,
    });

    return this.firstName?.toUpperCase() === driver.firstName?.toUpperCase() &&
      this.lastName?.toUpperCase() === driver.lastName?.toUpperCase() &&
      licenseObfucated1 === licenseObfucated2;
  }

  serializeForSubmission() {
    return {
      ...this.buildLicenseParams(),
      accidentPreventionCourse: this.accidentPreventionCourse,
      activeDutyMilitary: this.activeDutyMilitary,
      ageWhenLicensed: AgeWhenLicensed.print(this.ageWhenLicensed),
      collegeGraduate: this.collegeGraduate,
      dob: this.dob.toString(),
      firstName: this.firstName?.trim(),
      goodStudent: this.goodStudent,
      lastName: this.lastName?.trim(),
      maritalStatus: this.maritalStatus,
      nationalGuardMember: this.nationalGuardMember,
      occasionalDriver: this.occasionalDriver,
      pni: this.pni,
      refresherPreventionCourse: this.refresherPreventionCourse,
      universalDriverId: this.universalDriverId,
    };
  }

  serializeForSaving() {
    return {
      ...this,
      ageWhenLicensed: AgeWhenLicensed.print(this.ageWhenLicensed),
      dob: this.dob.toString(),
      firstName: this.firstName?.trim(),
      lastName: this.lastName?.trim(),
    };
  }
}
