import AbTestProvider from '@root/core/src/contexts/ab-test/ab-test-provider';
import AnalyticsProvider from '@root/core/src/contexts/analytics/analytics-provider';
import AnalyticsService from '@root/core/src/services/analytics-service';
import AuthController from '@root/auth/src/components/auth-controller';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import PropTypes from '@root/vendor/prop-types';
import ProtectedApp from '@root/account.joinroot.com/src/components/protected-app';
import PublicRouter from '@root/account.joinroot.com/src/components/public-router';
import SceneLoader from '@root/core/src/components/scene-loader';
import ScrollToTop from '@root/core/src/components/scroll-to-top';
import TrackingService from '@root/attribution/src/services/tracking-service';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Suspense, useEffect } from '@root/vendor/react';

const ACCOUNT_APP_ANALYTICS_PREFIX = 'ACCOUNT_APP';
const ACCOUNT_APP_DOMAIN = 'account.joinroot.com';

function App({ routerComponent: Router }) {
  useAnalytics('APP_ENTRY');

  useEffect(() => {
    TrackingService.fireDistinctIdSetEvent(AnalyticsService.getDistinctId());
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<SceneLoader />}>
          <AuthController
            passProps={{
              isAccountApp: true,
            }}
            ProtectedApp={ProtectedApp}
            PublicRouter={PublicRouter}
            SceneLoader={SceneLoader}
          />
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

App.propTypes = {
  routerComponent: PropTypes.func.isRequired,
};

export default function AppEntry({ initialAbTests = {}, routerComponent }) {
  return (
    <ErrorBoundary>
      <AbTestProvider initialAbTests={initialAbTests}>
        <AnalyticsProvider eventPrefix={ACCOUNT_APP_ANALYTICS_PREFIX}>
          <App routerComponent={routerComponent} />
        </AnalyticsProvider>
      </AbTestProvider>
    </ErrorBoundary>
  );
}

AppEntry.propTypes = {
  initialAbTests: PropTypes.object,
  routerComponent: PropTypes.func.isRequired,
};

export { ACCOUNT_APP_ANALYTICS_PREFIX, ACCOUNT_APP_DOMAIN };
