const environment = {
  accountAppBaseUrl: ENV.ACCOUNT_APP_BASE_URL,
  agentsAppBaseUrl: ENV.AGENTS_APP_BASE_URL,
  announcementBannerEnabled: ENV.ANNOUNCEMENT_BANNER_ENABLED === 'true',
  apiBaseUrl: ENV.API_BASE_URL,
  apiVersion: 7,
  appsFlyerOneLinkUrl: 'https://root.onelink.me/IoYM',
  attributionEnabled: ENV.ATTRIBUTION_ENABLED === 'true',
  isBoomerangEnabled: ENV.BOOMERANG_ENABLED === 'true',
  claimsAppBaseUrl: ENV.CLAIMS_APP_BASE_URL,
  contentfulPreviewEnabled: ENV.CONTENTFUL_PREVIEW_ENABLED === 'true',
  environment: ENV.ENVIRONMENT,
  facebookAppId: ENV.FACEBOOK_APP_ID,
  googleApiKey: ENV.GOOGLE_API_KEY,
  googleOauthClientId: ENV.GOOGLE_OAUTH_CLIENT_ID,
  incSiteBaseUrl: ENV.INC_SITE_BASE_URL,
  incSiteInvestorsLinkEnabled: ENV.INC_SITE_INVESTORS_LINK_ENABLED === 'true',
  ipinfoApiKey: ENV.IPINFO_API_KEY,
  joinrootNavRedesignEnabled: ENV.JOINROOT_NAV_REDESIGN_ENABLED === 'true',
  quoteAppBaseUrl: ENV.QUOTE_APP_BASE_URL,
  quoteAppDomain: ENV.QUOTE_APP_BASE_URL ? new URL(ENV.QUOTE_APP_BASE_URL).host : undefined,
  quotesPollerMaxPollAttempts: ENV.QUOTES_POLLER_MAX_POLL_ATTEMPTS && parseInt(ENV.QUOTES_POLLER_MAX_POLL_ATTEMPTS),
  quotesPollerPollAttemptTimeout: ENV.QUOTES_POLLER_POLL_ATTEMPT_TIMEOUT,
  renderAnalyticDetailsEnabled: ENV.RENDER_ANALYTICS_DETAILS_ENABLED === 'true',
  mixpanelToken: ENV.MIXPANEL_TOKEN,
  releaseSha: ENV.RELEASE_SHA,
  rentersAppBaseUrl: ENV.RENTERS_APP_BASE_URL,
  rightQuoteEnabled: ENV.RIGHT_QUOTE_ENABLED === 'true',
  partnersAdminAppBaseUrl: ENV.PARTNERS_ADMIN_APP_BASE_URL,
  partnersUserAppBaseUrl: ENV.PARTNERS_USER_APP_BASE_URL,
  sentryDsn: ENV.SENTRY_DSN,
  sentryTeam: ENV.SENTRY_TEAM,
  googlePayMerchantId: ENV.GOOGLE_PAY_MERCHANT_ID,
  googlePayEnvironment: ENV.GOOGLE_PAY_ENVIRONMENT,
  isGooglePayEnabled: ENV.GOOGLE_PAY_ENABLED === 'true',
  isApplePayEnabled: ENV.APPLE_PAY_ENABLED === 'true',
  baseUrl: ENV.BASE_URL,
  inactiveTimeout: ENV.INACTIVE_TIMEOUT && parseInt(ENV.INACTIVE_TIMEOUT),
  minutesTillSessionInactivity: ENV.MINUTES_TILL_SESSION_INACTIVITY && parseInt(ENV.MINUTES_TILL_SESSION_INACTIVITY),
  webFnolClaimantSwitchVersion: ENV.WEB_FNOL_CLAIMANT_SWITCH_VERSION,
  webFnolPhoneUserSwitchVersion: ENV.WEB_FNOL_PHONE_USER_SWITCH_VERSION,
  webFnolThirdPartyEnabled: ENV.WEB_FNOL_THIRD_PARTY_ENABLED === 'true',
  websiteBaseUrl: ENV.WEBSITE_BASE_URL,
  zendeskChatKey: ENV.ZENDESK_CHAT_KEY,
  zendeskChatEnabled: ENV.ZENDESK_CHAT_ENABLED === 'true',
  mediaAlphaIntegrationSrc: ENV.MEDIA_ALPHA_INTEGRATION_SRC,
  marketManagerAppBaseUrl: ENV.MARKET_MANAGER_APP_BASE_URL,
};

if (typeof window !== 'undefined') {
  // we expose __environment on the window for debugging purposes
  // these variables are all public by nature, so there's no risk with exposing them
  window.__environment = environment;
}

export default environment;
