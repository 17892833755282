import LandingHeader from '@root/core/src/components/landing-header';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import SplashRouter from '@root/splash/src/components/splash-router';
import { StyleSheet } from '@root/core/src/utils/styles';
import { withRouter } from '@root/core/src/components/route';

class SplashEntry extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    passProps: PropTypes.object.isRequired,
  };

  render() {
    return (
      <>
        <LandingHeader showSignInButton={false} />
        <div css={styles.container} >
          <SplashRouter
            location={location}
            passProps={this.props.passProps}
          />
        </div>
      </>
    );
  }
}

export default withRouter(SplashEntry);

const styles = StyleSheet.create({
  container: {
    marginTop: 63,
  },
});
