import Recase from '@root/vendor/recase';
import environment from '@root/core/src/utils/environment';

const recase = Recase.create({});

export default class NetworkRequestConfiguration {
  static Methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
  };

  constructor({
    url,
    endpointBase = 'web_api',
    endpoint,
    method,
    queryParameters = {},
    bodyParameters = {},
    successCodes = [200],
    errorCodes = [],
  }) {
    if (!Object.keys(NetworkRequestConfiguration.Methods).includes(method)) {
      throw new Error('UnknownMethodError');
    }

    this.endpoint = endpoint;
    this.url = url || getURL(endpointBase, endpoint, queryParameters);
    this.body = getBody(bodyParameters);
    this.method = method;
    this.successCodes = successCodes;
    this.errorCodes = errorCodes;
    this.stack = new Error().stack;
  }

  headers(additionalHeaders) {
    return new Headers({
      'Accept': 'application/json',
      'Appetize-Simulation': false,
      'Client-Api-Version': environment.apiVersion,
      'Client-App-Name': 'Root Web',
      'Client-Device': 'Browser',
      'Client-Framework': 'React',
      'Content-Type': 'application/json',
      ...additionalHeaders,
    });
  }
}

function getURL(endpointBase, endpoint, queryParameters) {
  if (!endpoint) {
    throw new Error('NoEndpointError');
  }

  const { apiBaseUrl } = environment;
  const url = `${apiBaseUrl}/${endpointBase}/${endpoint}`;

  const searchParams = new URLSearchParams();
  const recasedQueryParameters = recase.snakeCopy(queryParameters);
  const queryKeys = Object.keys(recasedQueryParameters);
  queryKeys.forEach((key) => {
    const queryValue = recasedQueryParameters[key];
    if (Array.isArray(queryValue)) {
      queryValue.forEach((value) => searchParams.append(`${key}[]`, value));
    } else {
      searchParams.append(key, queryValue);
    }
  });

  return queryKeys.length > 0 ? `${url}?${searchParams.toString()}` : url;
}

function getBody(body) {
  if (Object.keys(body).length === 0) {
    return;
  }

  return JSON.stringify(recase.snakeCopy(body));
}
