export default class AgeWhenLicensed {
  static MIN_AGE = 16;
  static MAX_AGE = 110;
  static LABEL = 'Age when first licensed';
  static ERROR_NOT_VALID = 'Please enter a valid age.';
  static ERROR_TOO_YOUNG = `Please enter a valid age more than or equal to ${AgeWhenLicensed.MIN_AGE} years old.`;
  static ERROR_TOO_OLD = `Please enter a valid age less than or equal to ${AgeWhenLicensed.MAX_AGE} years old.`;
  static ERROR_OLDER_THAN_CURRENT_AGE = 'Please enter a valid age less than or equal to your current age.';

  constructor(age) {
    this.age = age ? parseInt(age) : '';
  }

  static print(ageWhenLicensed) {
    if (typeof ageWhenLicensed !== 'undefined' && typeof ageWhenLicensed.age === 'number') {
      return `${ageWhenLicensed.age}`;
    }

    return undefined;
  }

  toString() {
    return `${this.age}`;
  }

  isOldEnough() {
    return this.isValid() && this.age >= AgeWhenLicensed.MIN_AGE;
  }

  isYoungEnough() {
    return this.isValid() && this.age <= AgeWhenLicensed.MAX_AGE;
  }

  isNotOlderThan(compareAge) {
    return this.isValid() && this.age <= compareAge;
  }

  isValid() {
    return /^\d{1,3}$/.test(this.age) && this.age > 0;
  }

  getError() {
    if (!this.isValid()) {
      return AgeWhenLicensed.ERROR_NOT_VALID;
    } else if (!this.isOldEnough()) {
      return AgeWhenLicensed.ERROR_TOO_YOUNG;
    } else if (!this.isYoungEnough()) {
      return AgeWhenLicensed.ERROR_TOO_OLD;
    }
    return '';
  }

  set(value) {
    return Object.assign(
      new AgeWhenLicensed(),
      this,
      {
        'age': value ? parseInt(value) : '',
      },
    );
  }
}
