import AuthService from '@root/core/src/services/auth-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import UserSessionService from '@root/user-session/src/services/user-session-service';
import environment from '@root/core/src/utils/environment';
import useLoginWithQueryParam from '@root/auth/src/hooks/use-login-with-query-param';
import useValidateToken from '@root/auth/src/hooks/use-validate-token';

export default function AuthController({
  ProtectedApp,
  PublicRouter,
  SceneLoader,
  passProps: passPropsFromProps = {},
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [isQueryParamAuthenticated, isValidatingQueryParam] = useLoginWithQueryParam();

  const isValidatingToken = useValidateToken((result) => {
    if (isQueryParamAuthenticated) {
      return;
    }

    const hasValidToken = !!result.data?.valid;

    if (!hasValidToken) {
      AuthService.clear();
    }

    setIsAuthenticated(hasValidToken);
  }, [isQueryParamAuthenticated]);

  const passProps = {
    onLogin: () => {
      setIsAuthenticated(true);
      UserSessionService.initializeSessionActivity();
    },
    onLogout: () => {
      AuthService.clear();
      UserSessionService.clear();
      setIsAuthenticated(false);
    },
    ...passPropsFromProps,
  };

  if (isValidatingToken || isValidatingQueryParam) {
    return <SceneLoader />;
  }

  if (isAuthenticated || isQueryParamAuthenticated) {
    return (
      <ProtectedApp
        inactiveTimeout={environment.inactiveTimeout}
        passProps={passProps}
      />
    );
  }

  return (
    <PublicRouter
      passProps={passProps}
    />
  );
}

AuthController.propTypes = {
  passProps: PropTypes.object,
  ProtectedApp: PropTypes.func.isRequired,
  PublicRouter: PropTypes.func.isRequired,
  SceneLoader: PropTypes.func.isRequired,
};
