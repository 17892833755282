import CookieStorageService from '@root/core/src/services/cookie-storage-service';

export default class QueryParamService {
  static KEY = 'core.query';
  static STORAGE_OPTIONS = {
    expires: 365, // 1 year
  };

  static PARAMETERS = [];

  static _joinQuery = (path, query) => [path, query].filter((value) => value).join('?');

  static parseQuery(queryString, allowedParameters = this.PARAMETERS) {
    const knownParams = Array.from(new URLSearchParams(queryString).entries())
      .filter(([key]) => allowedParameters.includes(key));

    return knownParams.reduce((params, [key, value]) => ({
      ...params,
      [key]: value,
    }), {});
  }

  static isValidUrl(value, allowedParameters = this.PARAMETERS) {
    const url = new URL(value);
    const params = Array.from(url.searchParams.entries());
    return !!(url && params.length && params.filter(([k]) => allowedParameters.includes(k)).length > 0);
  }

  static store(href) {
    if (this.isValidUrl(href)) {
      const url = new URL(href);
      CookieStorageService.set(this.KEY, this.parseQuery(url.search), this.STORAGE_OPTIONS);
    }
  }

  static read() {
    return CookieStorageService.has(this.KEY)
      ? CookieStorageService.get(this.KEY)
      : undefined;
  }

  static readFiltered() {
    const value = this.read();

    if (!value) {
      return null;
    }

    return Object.keys(value)
      .filter((k) => this.PARAMETERS.includes(k))
      .reduce((params, k) => ({
        ...params,
        [k]: value[k],
      }), {});
  }
}
