import * as Sentry from '@root/vendor/@sentry/browser';
import AppEntry from '@root/account.joinroot.com/src/app-entry';
import React from '@root/vendor/react';
import ReactDOM from '@root/vendor/react-dom';
import environment from '@root/core/src/utils/environment';
import { BrowserRouter } from '@root/vendor/react-router-dom';

const unsupportedBrowsers = [
  'Edge/17',
  'Edge/18',
];

const isUnsupportedBrowser = () => {
  unsupportedBrowsers.forEach((browser) => {
    if (window.navigator.userAgent && window.navigator.userAgent.indexOf(browser) >= 0) {
      return true;
    }
  });

  return false;
};

if (Object.entries && window.URLSearchParams && !isUnsupportedBrowser()) {
  Sentry.init({
    dsn: environment.sentryDsn,
    release: environment.releaseSha,
    environment: environment.environment,
  });

  ReactDOM.render(
    <AppEntry routerComponent={BrowserRouter} />,
    document.getElementById('root')
  );
}
