import * as Sentry from '@root/vendor/@sentry/react';
import PropTypes from '@root/vendor/prop-types';
import { Redirect as ReactRouterRedirect, Route as ReactRouterRoute } from '@root/vendor/react-router-dom';

export const Route = Sentry.withSentryRouting(ReactRouterRoute);

export function Redirect({
  to, shouldPreserveQueryParams = true, ...props
}) {
  if (!shouldPreserveQueryParams) {
    return (
      <ReactRouterRedirect
        to={to}
        {...props}
      />
    );
  }

  return (
    <Route
      component={({ location }) => (
        <ReactRouterRedirect
          to={{
            search: location.search,
            pathname: to,
          }}
        />
      )}
    />
  );
}

Redirect.propTypes = {
  shouldPreserveQueryParams: PropTypes.bool,
  to: PropTypes.any,
};

export { Switch, withRouter, useHistory, useLocation } from '@root/vendor/react-router-dom';
