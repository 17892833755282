import Col from '@root/core/src/components/col';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Row from '@root/core/src/components/row';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function SceneContent({ children, className }) {
  return (
    <Row
      className={className}
      css={styles.row}
    >
      <Col
        css={styles.wrapper}
        lg={6}
        md={8}
        sm={10}
        xl={4}
        xs={12}
      >
        <div css={styles.innerWrapper}>
          {children}
        </div>
      </Col>
    </Row>
  );
}

SceneContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const styles = StyleSheet.create({
  row: {
    position: 'absolute',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 90,
    alignItems: 'center',
    margin: '0 auto',
    ...Responsive.md({
      paddingBottom: 60,
    }),
  },
  innerWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
});
