import Header from '@root/core/src/components/header';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import UserAgentService from '@root/core/src/services/user-agent-service';
import { StyleSheet } from '@root/core/src/utils/styles';
import { withRouter } from '@root/core/src/components/route';

function PolicyholderPageWrapper({ children }) {
  const isRootMobile = UserAgentService.isRootMobileAgent();

  return (
    <>
      {!isRootMobile &&
        <Header />
      }
      <div css={isRootMobile ? styles.pageWithoutHeader : styles.page}>
        <div css={styles.body}>
          {children}
        </div>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  page: {
    height: 'calc(100vh - 63px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 63,
    padding: '0 10px',
  },
  pageWithoutHeader: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px',
  },
  body: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default withRouter(PolicyholderPageWrapper);

PolicyholderPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
