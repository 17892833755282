import dayjs from '@root/vendor/dayjs';

export default class DobDate {
  static DEFAULT_MIN_AGE = 14;
  static DEFAULT_MAX_AGE = 110;

  constructor(dateString, currentDate = new Date()) {
    this.month = dateString ? dateString.split('-')[1] : '';
    this.year = dateString ? dateString.split('-')[0] : '';
    this.day = dateString ? dateString.split('-')[2] : '';
    this.currentDate = dayjs(currentDate);
  }

  toString() {
    return `${this.year.padStart(4, '0')}-${this.month.padStart(2, '0')}-${this.day.padStart(2, '0')}`;
  }

  toDisplayString() {
    return `${this.month.padStart(2, '0')}-${this.day.padStart(2, '0')}-${this.year.padStart(4, '0')}`;
  }

  age(currentDate = this.currentDate) {
    return dayjs(currentDate).diff(this.toString(), 'years');
  }

  isValidDay() {
    return /^\d{1,2}$/.test(this.day) && parseInt(this.day) <= 31 && parseInt(this.day) >= 1;
  }

  isValidMonth() {
    return /^\d{1,2}$/.test(this.month) && parseInt(this.month) <= 12 && parseInt(this.month) >= 1;
  }

  isValidYear(options = {}) {
    const maxYear = options.maxYear || this.currentDate.year();

    return /^\d{4}$/.test(this.year) && parseInt(this.year) <= parseInt(maxYear);
  }

  isYearComplete() {
    return /^\d{4}$/.test(this.year);
  }

  isOldEnough({ currentDate = new Date(), minAge = DobDate.DEFAULT_MIN_AGE } = {}) {
    const minDate = dayjs(currentDate).subtract(minAge, 'years');

    return /^\d{4}$/.test(this.year) && !minDate.isBefore(this.toString(), 'day');
  }

  isYoungEnough({ currentDate = new Date(), maxAge = DobDate.DEFAULT_MAX_AGE } = {}) {
    const maxDate = dayjs(currentDate).subtract(maxAge + 1, 'years');

    return /^\d{4}$/.test(this.year) && maxDate.isBefore(this.toString(), 'day');
  }

  dateExists() {
    const dayjsDate = dayjs(this.toString(), 'YYYY-MM-DD');
    // dayjs will turn 2/29/19 into 3/1/19 -- to get around this, we check if the date got changed when it was passed in
    return dayjsDate.isValid() && dayjsDate.format('YYYY-MM-DD') === this.toString();
  }

  isValid(options = {}) {
    const maxAge = options.maxAge || DobDate.DEFAULT_MAX_AGE;
    const maxDate = options.maxDate || this.currentDate;
    const minDate = options.minDate || this.currentDate.year(dayjs().year() - maxAge - 2);

    const dateInputsValid = this.isValidDay() && this.isValidMonth() && this.isValidYear();

    if (dateInputsValid) {
      const dayjsDate = dayjs(this.toString(), 'YYYY-MM-DD');
      return this.dateExists() && dayjsDate.isBefore(maxDate) && dayjsDate.isAfter(minDate);
    } else {
      return false;
    }
  }

  set(key, value) {
    return Object.assign(
      new DobDate(),
      this,
      {
        [key]: value,
      },
    );
  }
}
