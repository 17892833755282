import PolicyholderPageWrapperPublic from '@root/account.joinroot.com/src/components/policyholder-page-wrapper-public';
import PropTypes from '@root/vendor/prop-types';
import React, { lazy } from '@root/vendor/react';
import { ACCOUNT_APP_DOMAIN } from '@root/account.joinroot.com/src/app-entry';
import { Redirect, Route, Switch } from '@root/core/src/components/route';

const LoginEntry = lazy(() => import(/* webpackChunkName: "login" */ '@root/login/src/login-entry'));
const PasswordResetEntry = lazy(() => import(/* webpackChunkName: "password-reset" */ '@root/password-reset/src/password-reset-entry'));
const SplashEntry = lazy(() => import(/* webpackChunkName: "openOnPhone" */ '@root/splash/src/splash-entry'));

export default function PublicRouter({ passProps }) {
  return (
    <Switch>
      <Route
        path={'/login/password'}
        render={() => {
          return (
            <PolicyholderPageWrapperPublic>
              <LoginEntry
                domain={ACCOUNT_APP_DOMAIN}
                isModuleInPath={false}
                module={'account.joinroot.com'}
                passProps={passProps}
              />
            </PolicyholderPageWrapperPublic>
          );
        }}
      />
      <Route
        path={'/password-reset'}
        render={() => <PasswordResetEntry passProps={passProps} />}
      />
      <Route
        path={'/splash'}
        render={() => <SplashEntry passProps={{}} />}
      />
      <Redirect to={'/login/password'} />
    </Switch>
  );
}

PublicRouter.propTypes = {
  passProps: PropTypes.object.isRequired,
};
