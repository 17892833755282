export const CLAIMS_PHONE = '(866) 980-9431';
export const ROADSIDE_PHONE = '(855) 982-1720';
export const SUPPORT_PHONE = '(866) 980-9431';

export const PHONE_NUMBER_MASK = ['(', /[2-9]/, /[0-8]/, /\d/, ')', ' ', /[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const NO_PHONE_NUMBER_MASK = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const ALTERNATE_PHONE_NUMBER_MASK = [/[2-9]/, /[0-8]/, /\d/, '-', /[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const VALID_PHONE_NUMBER_REGEX = /^([2-9][0-8]\d[2-9]\d{6})$/;
export const VALID_PHONE_NUMBER_REGEX_WITH_FORMATTING = /[(][2-9][0-8]\d[)] [2-9]\d{2}[-]\d{4}/;

export const cleanPhoneNumberValue = (rawValue) => {
  let newValue = rawValue.replace(/\D/g, '');
  newValue = _cleanFrontDigits(newValue);
  if (newValue.length > 10) { newValue = newValue.substr(0, 10); }
  return newValue;
};

const _cleanFrontDigits = (v) => {
  let newV = `${v}`;

  while (newV[0] === '0') { newV = newV.substr(1); }
  if (newV[0] === '1' && newV.length > 10) { newV = newV.substr(1); }

  return newV === v ? newV : _cleanFrontDigits(newV);
};
