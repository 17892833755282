import Error from '@root/core/src/components/error';
import ErrorReportService from '@root/core/src/services/error-report-service';
import NetworkConnectivityError from '@root/core/src/networking/network-connectivity-error';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

const CHUNK_FAILURE_REGEX = /Loading chunk.+failed/i;

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    showErrorScreen: PropTypes.bool,
  }

  static defaultProps = {
    showErrorScreen: true,
  };

  state = {
    hasError: false,
  }

  componentDidMount() {
    window.onunhandledrejection = this._handleUncaughtPromise;
    window.addEventListener('error', this._handleUncaughtError);
  }

  componentDidCatch(error, { componentStack }) {
    this._handleError(error, componentStack);
  }

  componentWillUnmount() {
    window.onunhandledrejection = null;
    window.removeEventListener('error', this._handleUncaughtError);
  }

  _handleUncaughtError = ({ error }) => {
    this._handleError(error, 'unhandled-error');
  }

  _handleUncaughtPromise = (promiseRejection) => {
    this._handleError(promiseRejection.reason || promiseRejection, 'unhandled-promise-rejection');
  }

  _handleError = (error, componentStack) => {
    if (!error || typeof error.message !== 'string' || error.stack?.includes('setAfUserId')) { return; }

    this._triageError(error);
    this._markBoundaryDirty();
    this._reportError(error, componentStack);
  }

  _handleErrorClick = () => {
    this._reload();
  }

  _reload() {
    window.location.reload(true); // eslint-disable-line root/prevent-use-of-window-location
  }

  _triageError(error) {
    if (error && CHUNK_FAILURE_REGEX.test(error.message)) {
      this._reload();
    }
  }

  _markBoundaryDirty() {
    if (!this.props.showErrorScreen) {
      return;
    }

    this.setState({
      hasError: true,
    });
  }

  _reportError(error, componentStack) {
    if (error instanceof NetworkConnectivityError) { return; }

    const additionalData = error?.additionalData || {};
    ErrorReportService.reportError({
      error,
      caughtAt: componentStack,
      additionalData,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Error
          buttonText={'Reload page'}
          onClick={this._handleErrorClick}
          showHeader={true}
        />
      );
    }

    return this.props.children;
  }
}
