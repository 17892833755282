import ExpectedNetworkError from '@root/core/src/networking/expected-network-error';
import NetworkConnectivityError from '@root/core/src/networking/network-connectivity-error';
import NetworkResult from '@root/core/src/networking/network-result';
import Recase from '@root/vendor/recase';
import UnexpectedNetworkError from '@root/core/src/networking/unexpected-network-error';
const recase = Recase.create({});

export default class NetworkRequestController {
  constructor(abortController, authToken) {
    this.abortController = abortController;
    this.authToken = authToken;
  }

  async request(networkRequestConfiguration) {
    const { method, body } = networkRequestConfiguration;
    const authHeaders = this.authToken ? {
      'AUTH_TOKEN': this.authToken,
    } : {};
    const headers = networkRequestConfiguration.headers(authHeaders);
    const fetchOptions = {
      headers,
      method,
      body,
      signal: this.abortController?.signal,
    };

    let response, data, error;
    try {
      response = await fetch(networkRequestConfiguration.url, fetchOptions);
      data = await this._getData(response);
      error = this._getError(response, networkRequestConfiguration);
    } catch (e) {
      error = new NetworkConnectivityError(e.message || e);
    }

    if (error) {
      error.stack = networkRequestConfiguration.stack;
      error.additionalData = {
        fetchBody: typeof fetchOptions.body === 'string' ? JSON.parse(fetchOptions.body) : fetchOptions.body,
        serverResponseData: data,
      };
    }

    return new NetworkResult(data, error, response, fetchOptions);
  }

  _getError(response, networkRequestConfiguration) {
    const { status: statusCode } = response;

    if (networkRequestConfiguration.successCodes.includes(statusCode)) {
      return null;
    }

    if (networkRequestConfiguration.errorCodes.includes(statusCode)) {
      return new ExpectedNetworkError();
    }

    return new UnexpectedNetworkError(`Unexpected ${statusCode} on ${networkRequestConfiguration.method} to ${networkRequestConfiguration.endpoint}`);
  }

  async _getData(response) {
    try {
      return recase.camelCopy(await response.json());
    } catch (e) {
      return {};
    }
  }
}
