import Policy from '@root/account.joinroot.com/src/models/policy';
import dayjs from '@root/vendor/dayjs';

export default class PolicyContext {
  constructor({
    policy, nextPaymentDate, upcomingBillingAmountInDollars, paymentStatus, nonPaymentCancellationDate, repaymentStrategy, canceled, futureCancelDate, upcomingRepayments,
  } = {}) {
    this.policy = policy;
    this.nextPaymentDate = nextPaymentDate;
    this.upcomingBillingAmountInDollars = upcomingBillingAmountInDollars;
    this.paymentStatus = paymentStatus;
    this.nonPaymentCancellationDate = nonPaymentCancellationDate;
    this.repaymentStrategy = repaymentStrategy;
    this.canceled = canceled;
    this.futureCancelDate = futureCancelDate;
    this.upcomingRepayments = upcomingRepayments;
  }

  static buildFromData({
    policy, nextPaymentDate, upcomingBillingAmountInDollars, paymentStatus, nonPaymentCancellationDate, repaymentStrategy, canceled, futureCancelDate, upcomingRepayments,
  } = {}) {
    return Object.assign(
      new PolicyContext(),
      {
        policy: Policy.buildFromData(policy),
        nextPaymentDate,
        upcomingBillingAmountInDollars,
        paymentStatus,
        nonPaymentCancellationDate,
        repaymentStrategy,
        canceled,
        futureCancelDate,
        upcomingRepayments,
      },
    );
  }

  static isSingleRepaymentOverdue = ({ isOverdue }) => isOverdue;

  isConditionalRepayment() {
    return this.repaymentStrategy === 'conditional';
  }

  isPaymentOverdue() {
    return this.paymentStatus === 'late';
  }

  isAnyPaymentOverdue() {
    return this.isPaymentOverdue() || this.isAnyRepaymentOverdue();
  }

  isAnyRepaymentOverdue() {
    return this.upcomingRepayments.some(PolicyContext.isSingleRepaymentOverdue);
  }

  overdueRepayments() {
    return this.upcomingRepayments.filter(PolicyContext.isSingleRepaymentOverdue);
  }

  firstNonPaymentCancellationDate() {
    const repaymentNonPaymentCancellationDates = this.upcomingRepayments.map(({ nonPaymentCancellationDate }) => nonPaymentCancellationDate);

    return repaymentNonPaymentCancellationDates.concat([this.nonPaymentCancellationDate]).sort((a, b) => {
      const dayjsA = dayjs(a);
      const dayjsB = dayjs(b);

      if (dayjsA.isBefore(dayjsB)) {
        return -1;
      } else if (dayjsA.isAfter(dayjsB)) {
        return 1;
      } else {
        return 0;
      }
    })[0];
  }
}
