import PropTypes from '@root/vendor/prop-types';
import React, { lazy } from '@root/vendor/react';
import { Redirect, Route, Switch } from '@root/core/src/components/route';

const BoomerangProfileReview = lazy(() => import(/* webpackChunkName: "boomerang-profile-review" */ '@root/boomerang/src/scenes/profile-review'));
const ProfileReviewEditAddress = lazy(() => import(/* webpackChunkName: "profile-review-edit-address" */ '@root/profile-review/src/scenes/profile-review-edit-address'));
const ProfileReviewEditDriver = lazy(() => import(/* webpackChunkName: "profile-review-edit-driver" */ '@root/profile-review/src/scenes/profile-review-edit-driver'));
const ProfileReviewEditVehicle = lazy(() => import(/* webpackChunkName: "profile-review-edit-vehicle" */ '@root/profile-review/src/scenes/profile-review-edit-vehicle'));

export const Routes = {
  BOOMERANG_PROFILE_REVIEW: '/boomerang/profile-review',
  BOOMERANG_PROFILE_REVIEW_EDIT_ADDRESS: '/boomerang/profile-review/edit-address',
  BOOMERANG_PROFILE_REVIEW_EDIT_DRIVER: '/boomerang/profile-review/edit-driver/:universalDriverId',
  BOOMERANG_PROFILE_REVIEW_EDIT_VEHICLE: '/boomerang/profile-review/edit-vehicle/:cid',
};

const routeDefinition = {
  [Routes.BOOMERANG_PROFILE_REVIEW]: BoomerangProfileReview,
  [Routes.BOOMERANG_PROFILE_REVIEW_EDIT_ADDRESS]: ProfileReviewEditAddress,
  [Routes.BOOMERANG_PROFILE_REVIEW_EDIT_DRIVER]: ProfileReviewEditDriver,
  [Routes.BOOMERANG_PROFILE_REVIEW_EDIT_VEHICLE]: ProfileReviewEditVehicle,
};

export default function BoomerangRouter({ location, passProps }) {
  return (
    <Switch location={location}>
      {Object.keys(routeDefinition).map((route) => (
        <Route
          exact={true}
          key={route}
          path={route}
          render={({ match }) => {
            const Scene = routeDefinition[route];

            return (
              <Scene
                {...match.params}
                {...passProps}
              />
            );
          }}
        />
      ))}
      <Redirect to={'/'} />
    </Switch>
  );
}

BoomerangRouter.propTypes = {
  location: PropTypes.object.isRequired,
  passProps: PropTypes.object.isRequired,
};
