import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function SceneWrapper({ children, className }) {
  return (
    <div
      className={className}
      css={styles.wrapper}
    >
      {children}
    </div>
  );
}

SceneWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 63,
    height: 'calc(100vh - 63px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    ...Responsive.sm({
      justifyContent: 'center',
    }),
  },
});
